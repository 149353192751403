//
// reboot.scss
//


// Forms
label {
    font-weight: $label-font-weight;
}


button:focus {
    outline: none;
}